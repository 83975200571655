<div class='block-container'>
  <div class='title'>Profile</div>
  <hr>
  <form [formGroup]='profileInfoForm' (ngSubmit)='profileSaveChanges()'>
    <label>
      Company Name *
      <input
        type='text'
        placeholder='Enter company name'
        formControlName='companyName'
        [class.error]='profileInfoControls.companyName.invalid && profileInfoControls.companyName.touched'
      >
      <div
        class='error'
        *ngIf='profileInfoControls.companyName.invalid && profileInfoControls.companyName.touched'
      >
        <div *ngIf="profileInfoControls.companyName.errors?.['required']">
          Company Name is required.
        </div>
      </div>
    </label>
    <label class='select-field'>
      Country *
      <input type='text'
             placeholder='Country'
             formControlName='country'
             [class.error]='profileInfoControls.country.invalid && profileInfoControls.country.touched'
             [matAutocomplete]='countryAutocomplete'>
      <mat-autocomplete
        (optionSelected)='onCountrySelection($event)'
        #countryAutocomplete='matAutocomplete'
        [displayWith]='displayName'
      >
        <mat-option *ngFor='let country of (filteredCountries$ | async)' [value]='country'>
          {{ country.name }}
        </mat-option>
      </mat-autocomplete>
      <img class='mat-select-arrow select-arrow' src='/assets/icons/select.svg' alt=''>
      <div
        class='error'
        *ngIf='profileInfoControls.country.invalid && profileInfoControls.country.touched'
      >
        <div *ngIf="profileInfoControls.country.errors?.['required']">
          Country is required.
        </div>
        <div *ngIf="profileInfoControls.country.errors?.['invalidValue']">
          Please select a valid Country.
        </div>
      </div>
    </label>
    <div *ngIf='(profileInfoControls.country.value && states.length) || !profileInfoControls.country.value'
         class='d-flex'>
      <label class='select-field'>
        State/Province/Region *
        <input type='text'
               placeholder='Region'
               formControlName='state'
               [class.error]='profileInfoControls.state.invalid && profileInfoControls.state.touched'
               [matAutocomplete]='regionAutocomplete'>
        <mat-autocomplete
          (optionSelected)='onStateSelection($event)'
          #regionAutocomplete='matAutocomplete'
          [displayWith]='displayName'
        >
          <mat-option *ngFor='let state of (filteredStates$ | async)' [value]='state'>
            {{ state.name }}
          </mat-option>
        </mat-autocomplete>
        <img class='mat-select-arrow select-arrow' src='/assets/icons/select.svg' alt=''>
        <div
          class='error'
          *ngIf='profileInfoControls.state.invalid && profileInfoControls.state.touched'
        >
          <div *ngIf="profileInfoControls.state.errors?.['required']">
            State/Province/Region is required.
          </div>
          <div *ngIf="profileInfoControls.state.errors?.['invalidValue']">
            Please select a valid State.
          </div>
        </div>
      </label>
      <label class='select-field'
             *ngIf='(profileInfoControls.state.value && cities.length) || !profileInfoControls.state.value'>
        City *
        <input type='text'
               placeholder='City'
               formControlName='city'
               [class.error]='profileInfoControls.city.invalid && profileInfoControls.city.touched'
               [matAutocomplete]='cityAutocomplete'>
        <mat-autocomplete #cityAutocomplete='matAutocomplete' [displayWith]='displayName'>
          <mat-option *ngFor='let city of (filteredCities$ | async)' [value]='city'>
            {{ city.name }}
          </mat-option>
        </mat-autocomplete>
        <img class='mat-select-arrow select-arrow' src='/assets/icons/select.svg' alt=''>
        <div
          class='error'
          *ngIf='profileInfoControls.city.invalid && profileInfoControls.city.touched'
        >
          <div *ngIf="profileInfoControls.city.errors?.['required']">
            City is required.
          </div>
          <div *ngIf="profileInfoControls.city.errors?.['invalidValue']">
            Please select a valid City.
          </div>
        </div>
      </label>
    </div>
    <label>
      Head Office Street Address
      <input
        type='text'
        placeholder='Enter company street address'
        formControlName='headOfficeAddress'
      >
    </label>
    <label>
      Company Website *
      <input
        type='text'
        placeholder='Enter company website/URL'
        formControlName='companyWebSite'
        [class.error]='profileInfoControls.companyWebSite.invalid && profileInfoControls.companyWebSite.touched'
      >
      <div
        class='error'
        *ngIf='profileInfoControls.companyWebSite.invalid && profileInfoControls.companyWebSite.touched'
      >
        <div *ngIf="profileInfoControls.companyWebSite.errors?.['required']">
          Company Website is required.
        </div>
        <div *ngIf="profileInfoControls.companyWebSite.errors?.['pattern']">
          Enter a valid website by entering https://www or http://www before the domain name.
        </div>
      </div>
    </label>
    <label>
      Company Phone Number *
      <input
        type='number'
        placeholder='Enter company contact number'
        formControlName='companyPhoneNumber'
        (keydown)='numericOnlyWithPlus($event)'
        [class.error]='profileInfoControls.companyPhoneNumber.invalid && profileInfoControls.companyPhoneNumber.touched'
      >
      <div
        class='error'
        *ngIf='profileInfoControls.companyPhoneNumber.invalid && profileInfoControls.companyPhoneNumber.touched'
      >
        <div *ngIf="profileInfoControls.companyPhoneNumber.errors?.['required']">
          Company Phone Number is required.
        </div>
      </div>
    </label>
    <label>
      Primary Contact *
      <input
        type='text'
        placeholder='Enter full name of primary contact'
        formControlName='primaryContact'
        [class.error]='profileInfoControls.primaryContact.invalid && profileInfoControls.primaryContact.touched'
      >
      <div
        class='error'
        *ngIf='profileInfoControls.primaryContact.invalid && profileInfoControls.primaryContact.touched'
      >
        <div *ngIf="profileInfoControls.primaryContact.errors?.['required']">
          Primary Contact is required.
        </div>
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>

<div class='block-container'>
  <form [formGroup]='industryForm' (ngSubmit)='industrySaveChanges()'>
    <label class='select-field'>
      Industry *
      <input type='text'
             placeholder='Select your industry'
             formControlName='industry'
             [class.error]='industryControls.industry.invalid && industryControls.industry.touched'
             [matAutocomplete]='industryAutocomplete'
      >
      <mat-autocomplete #industryAutocomplete='matAutocomplete' [displayWith]='displayName'>
        <mat-option *ngFor='let industry of industries' [value]='industry'>
          {{ industry.name }}
        </mat-option>
      </mat-autocomplete>
      <img class='mat-select-arrow select-arrow' src='/assets/icons/select.svg' alt=''>
      <div
        class='error'
        *ngIf='industryControls.industry.invalid && industryControls.industry.touched'
      >
        <div *ngIf="industryControls.industry.errors?.['required']">
          Industry is required.
        </div>
      </div>
    </label>
    <label class='select-field'>
      Company Size *
      <input type='text'
             formControlName='companySize'
             placeholder='Select company size'
             [class.error]='industryControls.companySize.invalid && industryControls.companySize.touched'
             [matAutocomplete]='companySizeAutocomplete'
      >
      <mat-autocomplete #companySizeAutocomplete='matAutocomplete' [displayWith]='displayViewValue'>
        <mat-option *ngFor='let size of companySizes' [value]='size'>
          {{ size.viewValue }}
        </mat-option>
      </mat-autocomplete>
      <img class='mat-select-arrow select-arrow' src='/assets/icons/select.svg' alt=''>
      <div
        class='error'
        *ngIf='industryControls.companySize.invalid && industryControls.companySize.touched'
      >
        <div *ngIf="industryControls.companySize.errors?.['required']">
          Company Size is required.
        </div>
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>

<div class='block-container'>
  <form (ngSubmit)='emailSaveChanges()'>
    <label class='email-label'>
      Email *
      <input
        class='email-input'
        type='text'
        placeholder='Email to be used for logging in'
        [formControl]='email'
        [class.error]='email.invalid && email.touched'
      />
      <div *ngIf='user?.user.confirmed && (!email.dirty || email.value === user.user.email)'
           class='verification-box info'>
        <img src='assets/icons/correct.svg' alt='img' width='10' height='10' />
        <label>verified</label>
      </div>
      <div *ngIf='!user?.user.confirmed && email.value === user.user.email' (click)='verifyEmail()' class='verification-box danger' matTooltip='Verify'
           matTooltipPosition='above'>
        <label>verify email</label>
      </div>
      <div
        class='error'
        *ngIf='email.invalid && email.touched'
      >
        <div *ngIf="email.errors?.['required']">
          Email is required.
        </div>
        <div *ngIf="email.errors?.['email']">
          Enter a valid email address.
        </div>
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>

<div class='block-container'>
  <form (ngSubmit)='phoneNumberSaveChanges()'>
    <label class='email-label'>
      Phone Number *
      <input
        class='email-input'
        type='text'
        placeholder='Enter new mobile number'
        [formControl]='phoneNumber'
        [class.error]='phoneNumber.invalid && phoneNumber.touched'
      />
      <div *ngIf='user?.user.isPhoneVerified && (!phoneNumber.dirty || phoneNumber.value == user.user.phoneNumber)'
           class='verification-box info'>
        <img src='assets/icons/correct.svg' alt='img' width='10' height='10' />
        <label>verified</label>
      </div>
      <div *ngIf='!user?.user.isPhoneVerified && phoneNumber.value == user.user.phoneNumber' (click)='verifyPhoneNumber()' class='verification-box danger' matTooltip='Verify'
           matTooltipPosition='above'>
        <label>verify number</label>
      </div>
      <div
        class='error'
        *ngIf='phoneNumber.invalid && phoneNumber.touched'
      >
        <div *ngIf="phoneNumber.errors?.['required']">
          Enter a valid mobile number with no spaces.
        </div>
      </div>
    </label>
    <hr />
    <button type='submit' class='primary-btn mt-0'>
      Save Changes
    </button>
  </form>
</div>

<ng-template #inboxPopupMsg>
  <div class='popup-message-section'>
    <p class='paragraph2'>
      An email was sent to have you verify this email address associated to this account.
    </p>
    <p class='paragraph2 mt-2'>
      Be sure to check your junk mail if it did not arrive in your inbox.
    </p>
  </div>
</ng-template>
